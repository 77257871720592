import React, { useState, useMemo, forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, layout, typography, color, variant, position, flexbox } from 'styled-system'
import propTypes from '@styled-system/prop-types'
import Link from 'next/link'

const BIGGEST = 'biggest'
const BIGGER = 'bigger'
const BIG = 'big'
const MEDIUM = 'medium'
const REGULAR = 'regular'
const SMALL = 'small'
const TINY = 'tiny'
const TEENY = 'teeny'

const LinkComponent = forwardRef(({ label, icon, href, passHref, ...props }, linkRef) => {
  const iconProp = useMemo(() => {
    if (typeof icon !== 'boolean') {
      return icon
    }
    return <LinkComponentIcon />
  }, [icon])

  return (
    <Link href={href} passHref={passHref}>
      <LinkComponentStyled display={icon ? 'flex' : 'inline-block'} alignItems='center' {...props}>
        {label}
        {icon && iconProp}
      </LinkComponentStyled>
    </Link>
  )
})

const LinkComponentIcon = styled.span`
  display: block;
  width: 13px;
  height: 11px;
  margin-left: 9px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMS45OTkiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxMS45OTkgMTEiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiNmZjZhNTg7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDExLjk5OSAxMSkgcm90YXRlKDE4MCkiPjxwYXRoIGNsYXNzPSJhIiBkPSJNNC41MTksMTAuODU2LjQxMSw2LjU0MWExLjUyNCwxLjUyNCwwLDAsMSwwLTIuMDgzTDQuNTE5LjE0NGEuNDU1LjQ1NSwwLDAsMSwuNjYzLDAsLjUwOC41MDgsMCwwLDEsMCwuNjk1TDEuMjExLDUuMDA5aDEwLjMyYS40OTEuNDkxLDAsMCwxLDAsLjk4MkgxLjIxM2wzLjk3MSw0LjE3YS41MDguNTA4LDAsMCwxLDAsLjY5NS40NTUuNDU1LDAsMCwxLS42NjMsMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMCkiLz48L2c+PC9zdmc+');
`

const LinkComponentStyled = styled.a(
  variant({
    variants: {
      [BIGGEST]: {
        fontSize: 42
      },
      [BIGGER]: {
        fontSize: 24
      },
      [BIG]: {
        fontSize: 20
      },
      [MEDIUM]: {
        fontSize: 18
      },
      [REGULAR]: {
        fontSize: 16
      },
      [SMALL]: {
        fontSize: 14
      },
      [TINY]: {
        fontSize: 12
      },
      [TEENY]: {
        fontSize: 9
      }
    }
  }),
  ({ textDecoration }) => `
    cursor: pointer;
    margin: 0px;
    color: #FF7708;
    font-weight: 600;
    text-decoration: ${textDecoration};
    transition: 0.3s;
    font-family: Sora;
    > span{
        transition: 0.3s;
    }
    &:hover{
        > span {
            filter: grayscale(1);
            margin-left: 14px;
        }
    }
  `,
  ({ hover }) => `
    &:hover{
      color: ${hover}
    }
  `,
  space,
  layout,
  typography,
  color,
  position,
  flexbox
)

LinkComponent.propTypes = {
  variant: PropTypes.oneOf([BIGGEST, BIGGER, BIG, MEDIUM, REGULAR, SMALL, TINY, TEENY]),
  ...propTypes.space,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.color,
  ...propTypes.position,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  hover: PropTypes.string
}

LinkComponent.defaultProps = {
  variant: 'regular',
  hover: '#4E5657',
  icon: false,
  passHref: true,
  href: '/'
}

export { LinkComponent }
