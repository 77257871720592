import React, { Component } from 'react'
import styled from 'styled-components'

import { getSocialThumbMatchMaker } from '../../utils/helpers'

export default class ImageComponent extends Component {
  constructor(props) {
    super(props)
    this.state = { intersected: false }
    this.observer = null
  }

  componentDidMount() {
    this.observer = new IntersectionObserver(entries => {
      const image = entries[0]
      if (image.isIntersecting) {
        this.setState({ intersected: true })
        this.observer.disconnect()
      }
    })

    this.observer.observe(this.imgTag)
  }

  componentWillUnmount() {
    this.observer.disconnect()
  }

  render() {
    const { src, alt, ...props } = this.props
    const image = typeof src === 'object' ? getSocialThumbMatchMaker(src) : src
    return (
      <Image
        src={this.state.intersected ? image : ''}
        alt={alt || ''}
        ref={elem => (this.imgTag = elem)}
        {...props}
      />
    )
  }
}

const Image = styled.img`
  max-width: 100%;
`
